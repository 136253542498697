import React, { memo, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import styled from 'styled-components';
import ActionButtons from './ActionButtons';
import { SliderContext } from './SliderContext';
import { inputNodes } from '../NodeTypesLists';
import { useHover } from '../../HoverContext';

const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  border: ${({ isHighlighted }) => (isHighlighted ? '2px dashed red' : 'none')};
`;

const Icon = styled.div`
  background-color: green;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    color: white;
    width: 16px;
    height: 16px;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  color: #1a2e56;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }

  .error {
    color: red;
  }
`;

const eventStatusMap = {
  INVITED: 'Invited',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  ATTENDED: 'Attended',
  NOSHOW: 'No Show',
  RECEIVED: 'Received',
};

function hasData(data) {
  let message = '';
  let className = 'status';

  if (!data || !data.actionType) {
    return { message: 'Invalid configuration', className: 'error' };
  }

  switch (data.actionType) {
    case 'FUNNEL_STATE_CHANGE':
      if (!data.funnelId || !data.stageId) {
        message = 'Select funnel and stage';
        className = 'error';
      } else {
        message = 'Funnel Selected';
      }
      break;

    case 'CREATE_CANDIDATE_EVENT':
      if (!data.condition?.value && !data.condition) {
        message = 'Select event condition';
        className = 'error';
      } else {
        message = data.condition?.value || data.condition;
        if (data.candidateFilterVal) {
          message = `${message} is ${data.candidateFilterVal}`;
        }
      }
      break;

    case 'EVENT_STATUS_CHANGE':
      if (!data.condition || !eventStatusMap[data.condition]) {
        message = 'Select event status';
        className = 'error';
      } else {
        message = eventStatusMap[data.condition];
      }
      break;

    case 'TAG_CHANGE':
      if (data.condition?.length > 0 && data.tagAction) {
        let actionName = 'Added';
        if (data?.tagAction === 'DELETE') {
          actionName = 'Removed';
        }
        message = `${actionName}: ${data.condition?.join(', ')}`;
      } else {
        message = 'Select tags';
        className = 'error';
      }
      break;

    case 'SINGLE_EDOC_STATUS_CHANGE':
      if (data.status?.length > 0 && data.docIds) {
        message = `Document selected`;
      } else {
        message = 'Please complete node';
        className = 'error';
      }
      break;

    case 'INTERVIEW_FORM_STATUS_CHANGE':
      if (data.status?.length > 0 && data.forms) {
        message = `Forms and status are selected`;
      } else {
        message = 'Please complete node';
        className = 'error';
      }
      break;
    ///////////////////////////////////////
    //////// VACANCY /////////////////////
    //////////////////////////////////////
    case 'VACANCY_STATUS_UPDATE':
      if (data.status?.length) {
        message = `Status selected`;
      } else {
        message = 'Please complete node';
        className = 'error';
      }
      break;
    default:
      if (!data.condition) {
        message = 'Configure condition';
        className = 'error';
      } else {
        message = data.condition.toLowerCase();
      }
  }

  return { message, className };
}

export default memo(({ id, data }) => {
  const { hoveredId } = useHover();
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const nodeType = inputNodes.find((node) => node.actionType === data?.actionType);
  const label = nodeType?.label;
  const IconComp = nodeType?.icon;

  const handleDoubleClick = () => {
    setIsSliderOpen(true);
  };

  const validation = hasData(data);

  return (
    <SliderContext.Provider value={{ isSliderOpen, setIsSliderOpen }}>
      <div onDoubleClick={handleDoubleClick}>
        <ActionButtons id={id} data={data} disableCopy />
        <Container isHighlighted={id === hoveredId}>
          <Icon>
            <IconComp />
          </Icon>
          <Text>
            <div className="title">{label}</div>
            <div className={validation.className}>{validation.message}</div>
          </Text>
        </Container>
        <Handle type="source" position={Position.Bottom} />
      </div>
    </SliderContext.Provider>
  );
});
