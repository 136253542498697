import {
  File,
  CalendarDays,
  Filter,
  User,
  BriefcaseBusiness,
  GraduationCap,
  Landmark,
  Mail,
  Network,
  Users,
  SendHorizontal,
  Clock,
  ThumbsUp,
  BadgeCheck,
  DoorOpen,
  Tag,
  ScrollText,
  CircleX,
  Sparkles,
  UserCheck,
} from 'lucide-react';

const actionNodes = [
  { type: 'actionNode', label: 'Request Onboarding', actionType: 'REQUEST_ONBOARDING', icon: Mail },
  { type: 'actionNode', label: 'Update Tag', actionType: 'UPDATE_TAGS', icon: Tag },
  { type: 'actionNode', label: 'Request Referees', actionType: 'REQUEST_REFEREES', icon: Users, isEditDisabled: true },
  { type: 'actionNode', label: 'Send to Integration', actionType: 'SEND_TO_INTEGRATION', icon: SendHorizontal },
  {
    type: 'actionNode',
    label: 'Request FlexiForm',
    actionType: 'REQUEST_GENERIC_FORM',
    icon: Mail,
  },
  {
    type: 'actionNode',
    label: 'Request Interviewer AI Link',
    actionType: 'REQUEST_INTERVIEWER_AI',
    icon: Sparkles,
  },
  {
    type: 'actionNode',
    label: 'Request Right to Work Docs',
    actionType: 'REQUEST_RTW',
    icon: Mail,
  },
  {
    type: 'actionNode',
    label: 'Request EDocuments',
    actionType: 'REQUEST_EDOC',
    icon: Mail,
  },
  {
    type: 'actionNode',
    label: 'Request Personality Test',
    actionType: 'REQUEST_PERSONALITY_TEST',
    icon: SendHorizontal,
  },
  {
    type: 'actionNode',
    label: 'Send Email',
    actionType: 'SEND_EMAIL',
    icon: Mail,
  },
  { type: 'actionNode', label: 'Update Funnel Stage', actionType: 'UPDATE_FUNNEL_STAGE', icon: Filter },
  {
    type: 'actionNode',
    label: 'Request References',
    actionType: 'REQUEST_REFERENCES',
    icon: Users,
    isEditDisabled: true,
  },
  {
    type: 'actionNode',
    label: 'Reject all candidates',
    actionType: 'REJECT_CANDIDATES_VACANCY',
    icon: CircleX,
  },
];

const waitNodes = [{ type: 'waitNode', label: 'Wait', actionType: 'WAIT', icon: Clock }];

const decisionNodes = [
  { type: 'decisionNode', label: 'Decision', actionType: 'BRANCH', iconColour: 'inherit', icon: Network },
];

const notesNodes = [{ type: 'notesNode', label: 'Notes', actionType: 'NOTES', icon: File }];
const exitNodes = [{ type: 'endNode', label: 'Exit Workflow', actionType: 'EXIT', icon: DoorOpen }];

const inputNodes = [
  {
    type: 'startNode',
    label: 'EDoc Status Change',
    actionType: 'EDOC_STATUS_CHANGE',
    actions: ['SEND_EMAIL', 'UPDATE_FUNNEL_STAGE', 'REQUEST_GENERIC_FORM', 'UPDATE_TAGS'],
    icon: File,
  },
  {
    type: 'startNode',
    label: 'Specific Edoc Status Change',
    actionType: 'SINGLE_EDOC_STATUS_CHANGE',
    actions: [
      'UPDATE_FUNNEL_STAGE',
      'REQUEST_ONBOARDING',
      'REQUEST_RTW',
      'REQUEST_REFEREES',
      'SEND_EMAIL',
      'REQUEST_GENERIC_FORM',
      'UPDATE_TAGS',
    ],
    icon: ScrollText,
  },
  {
    type: 'startNode',
    label: 'Event Status Change',
    actionType: 'EVENT_STATUS_CHANGE',
    actions: [
      'REQUEST_ONBOARDING',
      'REQUEST_REFEREES',
      'SEND_TO_INTEGRATION',
      'REQUEST_GENERIC_FORM',
      'REQUEST_RTW',
      'REQUEST_EDOC',
      'REQUEST_PERSONALITY_TEST',
      'SEND_EMAIL',
      'UPDATE_FUNNEL_STAGE',
      'UPDATE_TAGS',
    ],
    icon: CalendarDays,
  },

  {
    type: 'startNode',
    label: 'FlexiForm Status Change',
    actionType: 'GENERIC_FORM_STATUS_CHANGE',
    actions: [
      'UPDATE_FUNNEL_STAGE',
      'REQUEST_ONBOARDING',
      'REQUEST_REFEREES',
      'REQUEST_RTW',
      'REQUEST_EDOC',
      'SEND_EMAIL',
      'REQUEST_GENERIC_FORM',
      'UPDATE_TAGS',
    ],
    icon: File,
  },
  {
    type: 'startNode',
    label: 'Funnel Status Change',
    actionType: 'FUNNEL_STATE_CHANGE',
    actions: [
      'REQUEST_ONBOARDING',
      'REQUEST_REFEREES',
      'SEND_TO_INTEGRATION',
      'REQUEST_GENERIC_FORM',
      'REQUEST_INTERVIEWER_AI',
      'REQUEST_RTW',
      'REQUEST_EDOC',
      'REQUEST_PERSONALITY_TEST',
      'SEND_EMAIL',
      'UPDATE_FUNNEL_STAGE',
      'UPDATE_TAGS',
    ],
    icon: Filter,
  },
  {
    type: 'startNode',
    label: 'Interview Form Status Change',
    actionType: 'INTERVIEW_FORM_STATUS_CHANGE',
    actions: ['SEND_EMAIL', 'UPDATE_FUNNEL_STAGE', 'REQUEST_GENERIC_FORM', 'UPDATE_TAGS'],
    icon: UserCheck,
  },
  {
    type: 'startNode',
    label: 'Interview AI Status Change',
    actionType: 'INTERVIEWER_AI_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'SEND_EMAIL', 'UPDATE_TAGS'],
    icon: Sparkles,
  },
  {
    type: 'startNode',
    label: 'New Candidate',
    actionType: 'CREATE_CANDIDATE_EVENT',
    actions: [
      'REQUEST_ONBOARDING',
      'REQUEST_REFEREES',
      'SEND_TO_INTEGRATION',
      'REQUEST_GENERIC_FORM',
      'REQUEST_RTW',
      'REQUEST_EDOC',
      'REQUEST_PERSONALITY_TEST',
      'SEND_EMAIL',
      'UPDATE_FUNNEL_STAGE',
      'UPDATE_TAGS',
    ],
    icon: User,
  },
  {
    type: 'startNode',
    label: 'Onboarding Status Change',
    actionType: 'ONBOARDING_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'REQUEST_REFEREES', 'SEND_EMAIL', 'REQUEST_GENERIC_FORM', 'UPDATE_TAGS'],
    icon: BriefcaseBusiness,
  },
  {
    type: 'startNode',
    label: 'LMS Status Change',
    actionType: 'LMS_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'REQUEST_REFEREES', 'SEND_EMAIL', 'REQUEST_GENERIC_FORM', 'UPDATE_TAGS'],
    icon: GraduationCap,
  },
  {
    type: 'startNode',
    label: 'Payroll Submission',
    actionType: 'PAYROLL_INTEGRATION_SUBMISSION',
    actions: ['UPDATE_FUNNEL_STAGE', 'REQUEST_REFEREES', 'SEND_EMAIL', 'REQUEST_GENERIC_FORM', 'UPDATE_TAGS'],
    icon: Landmark,
  },
  {
    type: 'startNode',
    label: 'Personality Test Status Change',
    actionType: 'PERSONALITY_TEST_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'SEND_EMAIL', 'REQUEST_GENERIC_FORM'],
    icon: ThumbsUp,
  },
  {
    type: 'startNode',
    label: 'Referee Status Change',
    actionType: 'REFEREE_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'REQUEST_REFERENCES', 'SEND_EMAIL', 'REQUEST_GENERIC_FORM', 'UPDATE_TAGS'],
    icon: Users,
  },
  {
    type: 'startNode',
    label: 'Reference Status Change',
    actionType: 'REFERENCE_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'SEND_EMAIL', 'REQUEST_GENERIC_FORM', 'UPDATE_TAGS'],
    icon: Users,
  },
  {
    type: 'startNode',
    label: 'Right To Work Status Change',
    actionType: 'RTW_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'SEND_EMAIL', 'REQUEST_GENERIC_FORM', 'UPDATE_TAGS'],
    icon: BadgeCheck,
  },
  {
    type: 'startNode',
    label: 'Tag Change',
    actionType: 'TAG_CHANGE',
    actions: [
      'REQUEST_ONBOARDING',
      'REQUEST_REFEREES',
      'REQUEST_GENERIC_FORM',
      'REQUEST_RTW',
      'SEND_EMAIL',
      'UPDATE_FUNNEL_STAGE',
      'SEND_EMAIL',
    ],
    icon: Tag,
  },
  //////////////////////////////////
  /////// VACANCY OPTIONS //////////
  /////////////////////////////////
  {
    type: 'startNode',
    label: 'Vacancy Status Change',
    actionType: 'VACANCY_STATUS_UPDATE',
    actions: ['SEND_EMAIL', 'REJECT_CANDIDATES_VACANCY'],
    icon: BriefcaseBusiness,
  },
];

export { actionNodes, waitNodes, decisionNodes, notesNodes, exitNodes, inputNodes };
