import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { getFlexiFormViewIdsByType } from '@API/FormsAPI/ATSFormsAPI';
import { retryableAPICall } from '@API/common-api-utils';

const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function SelectFormInput({ option, setOption, type }) {
  const [docs, setDocs] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);

  async function getForms() {
    const result = await retryableAPICall(() => getFlexiFormViewIdsByType(type));

    if (typeof result === 'string') {
      toast.error(`Failed to get ${type}`);
    } else {
      const genericFormEntries = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(result)) {
        genericFormEntries.push({ id: key, label: value, value: key });
      }
      setDocs(genericFormEntries);
      setSelectedDoc(
        genericFormEntries.find((a) => {
          return a.value === option;
        }),
      );
    }
  }

  useEffect(() => {
    getForms();
  }, []);

  useEffect(() => {
    setSelectedDoc(
      docs.find((a) => {
        return a.value === option;
      }),
    );
  }, [option]);

  return (
    <Select
      id={`${type}-form-select`}
      onChange={(val) => {
        setOption(val.value);
      }}
      options={docs}
      placeholder={`Select ${capitalizeFirstLetter(type.toLowerCase())}`}
      value={selectedDoc}
    />
  );
}

SelectFormInput.propTypes = {
  option: PropTypes.shape(),
  setOption: PropTypes.func,
  type: PropTypes.string,
};

SelectFormInput.defaultProps = {
  option: {},
  setOption: () => {},
  type: 'EDOC',
};

export default SelectFormInput;
