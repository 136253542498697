export const payrollStates = [
  { label: 'Failed to submit', value: 'FAILED' },
  { label: 'Submitted Successfully', value: 'SUCCESS' },
];

export const defaultStates = [
  { label: 'Requested', value: 'REQUESTED' },
  { label: 'Received', value: 'RECEIVED' },
];

export const referenceStates = [
  { label: 'Requested', value: 'REQUESTED' },
  { label: 'Part Received', value: 'PART_RECEIVED' },
  { label: 'Received', value: 'RECEIVED' },
];

export const rtwStates = [
  { label: 'Requested', value: 'REQUESTED' },
  { label: 'Received', value: 'RECEIVED' },
];
export const rtwOutcomes = [
  { value: 'ANY', label: 'Any' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'TIME_LIMITED', label: 'Time Limited' },
  { value: 'CONTINUOUS', label: 'Continuous' },
];

export const personalityTestState = [
  { label: 'Requested', value: 'REQUESTED' },
  { label: 'Received', value: 'RECEIVED' },
];

export const lmsState = [
  { label: 'Requested', value: 'REQUESTED' },
  { label: 'Received', value: 'RECEIVED' },
  { label: 'Withdrawn', value: 'WITHDRAWN' },
];

export const personalityTestScoreValues = [
  { value: 'ANY', label: 'Any' },
  { value: 'LT', label: 'Less Than' },
  { value: 'EQ', label: 'Equal to' },
  { value: 'GT', label: 'Greater Than' },
];

export const personalityTestScoreTypes = [
  { value: 'INTEGRITYSCORE', label: 'Integrity Score' },
  { value: 'OTHER', label: 'Other' },
];

export const eventStates = [
  { label: 'Invited', value: 'INVITED' },
  { label: 'Accepted', value: 'ACCEPTED' },
  { label: 'Declined', value: 'DECLINED' },
  { label: 'Attended', value: 'ATTENDED' },
  { label: 'No Show', value: 'NOSHOW' },
];

export const newCandidateFilterTypes = [
  { label: 'All', value: 'ALL' },
  { label: 'UTM Source', value: 'UTMSOURCE' },
  { label: 'UTM Campaign', value: 'UTMCAMPAIGN' },
  { label: 'UTM Medium', value: 'UTMMEDIUM' },
];

export const documentStatusTypes = [
  { label: 'Received', value: 'RECEIVED' },
  { label: 'Reminder email sent', value: 'REMINDER_1_SENT' },
  { label: 'Requested', value: 'REQUESTED' },
  { label: 'Withdrawn', value: 'WITHDRAWN' },
  { label: '2nd Reminder email sent', value: 'REMINDER_2_SENT' },
];

export const interviewStatusTypes = [
  { label: 'Received', value: 'RECEIVED' },
  { label: 'Requested', value: 'REQUESTED' },
  { label: 'Withdrawn', value: 'WITHDRAWN' },
];

export const vacancyStatusTypes = [
  { label: 'Authorization Rejected', value: 'AUTHORISATION_REJECTED' },
  { label: 'Awaiting Authorization', value: 'AWAITING_AUTHORISATION' },
  { label: 'Closed', value: 'CLOSED' },
  { label: 'Deleted', value: 'DELETED' },
  { label: 'Open', value: 'ACTIVE' },
  { label: 'Paused', value: 'PAUSED' },
  { label: 'Pending', value: 'PENDING' },
];

export default {
  documentStatusTypes,
  interviewStatusTypes,
  payrollStates,
  defaultStates,
  referenceStates,
  rtwStates,
  rtwOutcomes,
  personalityTestState,
  personalityTestScoreValues,
  personalityTestScoreTypes,
  eventStates,
  newCandidateFilterTypes,
  vacancyStatusTypes,
};
